import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Select from 'react-select';
import { withRouter } from "../components/WithRouter";
import { DropDownStyle, apiUrl } from '../helpers';
import Spinner from '../components/Spinner';
import { Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, Button } from '../components';
import { Alert } from 'react-bootstrap';

const PageContainerWrapper = styled(PageContainer)`
  .old-step {
    text-decoration: line-through;
    color: red;
  }

  .new-step {
    font-weight: bold;
    color: green;
  }
`;

const ContentfulLibraryView = () => {
    const [loading, setLoading] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [libraryPages, setLibraryPages] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedSection, setSelectedSection] = useState('');

    useEffect(() => {
        getSections();
    }, []);

    const getSections = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(apiUrl('/api/contentful/library_sections'));
            setSections(payload);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const getPagesBySections = async (sectionId) => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(apiUrl('/api/contentful/library_pages/' + sectionId));
            setLibraryPages(payload);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const groupItemsByStepNumber = (items) => {
        return items.reduce((acc, item) => {
            const key = item.newStepNumber;
            acc[key] = acc[key] || [];
            acc[key].push(item);
            return acc;
        }, {});
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const groupedItems = groupItemsByStepNumber(libraryPages);
        const groupKeys = Object.keys(groupedItems);

        // Reorder groups based on drag-and-drop
        const [reorderedGroup] = groupKeys.splice(result.source.index, 1);
        groupKeys.splice(result.destination.index, 0, reorderedGroup);

        // Flatten groups into a single array and update `newStepNumber`
        const updatedItems = groupKeys.flatMap((key, groupIndex) =>
            groupedItems[key].map((item) => ({
                ...item,
                oldStepNumber: item.stepNumber,
                newStepNumber: groupIndex, // Start from 0 instead of 1
                updated: item.stepNumber !== groupIndex, // Compare with new index
            }))
        );

        setLibraryPages(updatedItems);
    };

    const updateStepSaveStepNumber = async () => {
        try {
            setSaveInProgress(true);
            const updatedEntries = libraryPages.filter((entry) => entry.updated);
            await axios.post('/api/contentful/library_page/update', { entries: updatedEntries });
            getPagesBySections(selectedSection);
            setSaveInProgress(false);
        } catch (error) {
            console.error(error);
            setSaveInProgress(false);
        }
    };

    const onDropDownChange = (dt) => {
        setSelectedSection(dt.value);
        getPagesBySections(dt.value);
    };

    const onStepNumberInputChange = (e, dt) => {
        let newStepNumber = parseInt(e.target.value, 10); // Get the new step number from the input
        if (isNaN(newStepNumber) || newStepNumber < 0) newStepNumber = 0; // Validate input (must be a non-negative integer)

        // Update the step number for the selected item
        setLibraryPages((prevPages) => {
            const updatedPages = prevPages.map((item) => {
                if (item.id === dt.id) {
                    return {
                        ...item,
                        newStepNumber, // Set the manually updated step number
                        updated: item.stepNumber !== newStepNumber, // Mark as updated if changed
                    };
                };
                return item;
            });

            // Ensure the new step number is unique by reordering items
            return updatedPages.sort((a, b) => a.newStepNumber - b.newStepNumber || a.title.localeCompare(b.title));
        });
    };


    return (
        <PageContainerWrapper>
            {loading && <Spinner />}
            <Select styles={DropDownStyle} onChange={onDropDownChange} options={sections} />
            {!selectedSection ?
                <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="info">
                    <h2 className='w-100 mb-0 text-center'>
                        To view the pages list, please select a section first.
                    </h2>
                </Alert>
                :
                <>
                    <TableContainer className="mt-3" maxHeight="83vh">
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="droppableTable">
                                {(provided) => (
                                    <Table {...provided.droppableProps} ref={provided.innerRef} className="table table-bordered">
                                        <TableHead>
                                            <TableRow>
                                                <TableHeaderElement className="w-30">Title</TableHeaderElement>
                                                <TableHeaderElement className="w-10">State</TableHeaderElement>
                                                <TableHeaderElement className="w-20">Provider Type</TableHeaderElement>
                                                <TableHeaderElement className="text-end w-20">Step Number</TableHeaderElement>
                                                <TableHeaderElement className="text-end w-20">Old Step Number</TableHeaderElement>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.entries(groupItemsByStepNumber(libraryPages)).map(([stepNumber, group], index) => (
                                                <Draggable key={stepNumber} draggableId={stepNumber} index={index}>
                                                    {(provided) => (
                                                        <tr
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <TableDataElement colSpan={7}>
                                                                {group.map((dt, idx) => (
                                                                    <div key={dt.id} className="d-flex justify-content-between">
                                                                        <span className='w-30'>{dt.title}</span>
                                                                        <span className='w-10'>{dt.state?.join(', ')}</span>
                                                                        <span className='w-20'>{dt.providerType?.join(', ')}</span>
                                                                        <span className='w-20 text-center'>
                                                                            <input
                                                                                className='form-control' type="number"
                                                                                value={dt.newStepNumber}
                                                                                onChange={(e) => onStepNumberInputChange(e, dt)}
                                                                            />
                                                                        </span>
                                                                        <span className={dt.updated ? 'old-step w-20 text-center' : 'w-20 text-center'}>
                                                                            {dt.oldStepNumber}
                                                                        </span>
                                                                    </div>
                                                                ))}
                                                            </TableDataElement>
                                                        </tr>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </TableBody>
                                    </Table>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </TableContainer>
                    <Button type="button" disabled={saveInProgress} onClick={updateStepSaveStepNumber}>
                        {saveInProgress ? 'Update In Progress...' : 'Update'}
                    </Button>
                </>}
        </PageContainerWrapper>
    );
};

export default withRouter(ContentfulLibraryView);
