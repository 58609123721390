import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import { withRouter } from "../components/WithRouter";
import { DropDownStyle, apiUrl } from '../helpers';
import Spinner from '../components/Spinner';
import { Table, TableHead, TableBody, TableHeaderElement, TableDataElement, TableRow, TableContainer, PageContainer, Button } from '../components';
import { useNavigate } from 'react-router-dom';

const PageContainerWrapper = styled(PageContainer)`
.old-step {
  text-decoration: line-through;
  color: red;
}

/* Style for new step number */
.new-step {
  font-weight: bold;
  color: green;
}
`;

const ContentfulLibrarySetUpStep = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [entries, setEntries] = useState([]);

    useEffect(() => {
        getEntries();
    }, []);

    const getEntries = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get(apiUrl('/api/contentful/library_setup_step'));
            setEntries(payload);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };


    const groupItemsByStepNumber = (items) => {
        return items.reduce((acc, item) => {
            const key = item.newStepNumber;
            acc[key] = acc[key] || [];
            acc[key].push(item);
            return acc;
        }, {});
    };

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const groupedItems = groupItemsByStepNumber(entries);
        const groupKeys = Object.keys(groupedItems);

        // Reorder groups based on drag-and-drop
        const [reorderedGroup] = groupKeys.splice(result.source.index, 1);
        groupKeys.splice(result.destination.index, 0, reorderedGroup);

        // Flatten groups into a single array and update `newStepNumber`
        const updatedItems = groupKeys.flatMap((key, groupIndex) =>
            groupedItems[key].map((item) => ({
                ...item,
                oldStepNumber: item.stepNumber,
                newStepNumber: groupIndex + 1, // Start from 1 instead of 0
                updated: item.stepNumber !== groupIndex + 1, // Compare with new index
            }))
        );

        setEntries(updatedItems);
    };

    const updateStepSaveStepNumber = async () => {
        try {
            setSaveInProgress(true);
            const updated_entries = entries.filter((entry) => entry.updated);
            const { data } = await axios.post('/api/contentful/library_setup_step/update', { entries: updated_entries });
            getEntries();
            setSaveInProgress(false);
        } catch (error) {
            console.error(error);
            setSaveInProgress(false);
        }
    };

    const onStepNumberInputChange = (e, dt) => {
        let newStepNumber = parseInt(e.target.value, 10); // Get the new step number from the input
        if (isNaN(newStepNumber) || newStepNumber < 0) newStepNumber = 0; // Validate input (must be a non-negative integer)

        // Update the step number for the selected item
        setEntries((prevPages) => {
            const updatedPages = prevPages.map((item) => {
                if (item.id === dt.id) {
                    return {
                        ...item,
                        newStepNumber, // Set the manually updated step number
                        updated: item.stepNumber !== newStepNumber, // Mark as updated if changed
                    };
                };
                return item;
            });

            // Ensure the new step number is unique by reordering items
            return updatedPages.sort((a, b) => a.newStepNumber - b.newStepNumber || a.label.localeCompare(b.label));
        });
    };

    const navigateToLibraryPage = () => {
        navigate('/contentful/library_pages');
    };

    return (
        <PageContainerWrapper>
            {loading && <Spinner />}
            <div className="d-flex mb-3">
                {/* <button className='btn btn-outline-success'>Library Setup Step</button> */}
                <button onClick={navigateToLibraryPage} className='btn btn-outline-success mx-2' >Library Pages</button>
            </div>
            <>
                <TableContainer className="mt-3" maxHeight="83vh">
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="droppableTable">
                            {(provided) => (
                                <Table {...provided.droppableProps} ref={provided.innerRef} className="table table-bordered">
                                    <TableHead>
                                        <TableRow>
                                            <TableHeaderElement className='w-35' scope="col">
                                                Associated Variable
                                            </TableHeaderElement>
                                            <TableHeaderElement className='text-end w-30' scope="col">
                                                Step Number
                                            </TableHeaderElement>
                                            <TableHeaderElement className='text-end w-30' scope="col">
                                                Old Step Number
                                            </TableHeaderElement>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(groupItemsByStepNumber(entries)).map(([stepNumber, group], index) => (
                                            <Draggable key={stepNumber} draggableId={stepNumber} index={index}>
                                                {(provided) => (
                                                    <tr
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <TableDataElement colSpan={7}>
                                                            {group.map((dt, idx) => (
                                                                <div key={dt.id} className="d-flex justify-content-between">
                                                                    <span className='w-35'>
                                                                        <a href={dt.url} target="_blank" rel="noopener noreferrer" title={dt.url}>
                                                                            {dt.label}
                                                                        </a>
                                                                    </span>
                                                                    <span className='w-30 text-center'>
                                                                        <input
                                                                            className='form-control' type="number"
                                                                            value={dt.newStepNumber}
                                                                            onChange={(e) => onStepNumberInputChange(e, dt)}
                                                                        />
                                                                    </span>
                                                                    <span className={dt.updated ? 'old-step w-30 text-center' : 'w-30 text-center'}>
                                                                        {dt.oldStepNumber}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </TableDataElement>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </TableBody>
                                </Table>
                            )}
                        </Droppable>
                    </DragDropContext>
                </TableContainer>
                <Button type="button" disabled={saveInProgress} onClick={updateStepSaveStepNumber}>
                    {saveInProgress ? 'Update In Progress...' : 'Update'}
                </Button>
            </>
        </PageContainerWrapper>
    );
};

export default withRouter(ContentfulLibrarySetUpStep);
